import { Link } from 'react-router-dom'
import { ROUTES } from '../../_shared/constants/routes'
import styles from './home.module.scss'

function Home() {
  return (
    <div className={styles.homePageContainer}>
      <div className={styles.cardsContainer}>

        <div className={styles.card}>
          <p className={styles.title}>Ziroh Enterprise</p>
          <p className={styles.subTitle}>Advanced Data Security and Privacy Solutions for Enterprises.</p>
          <Link to={ROUTES.ENTERPRISE} target="_blank">enterprise.ziroh.com</Link>
        </div>

        <div className={styles.card}>
          <p className={styles.title}>Zunu Privacy Suite</p>
          <p className={styles.subTitle}>Privacy-first applications to protect your personal data, designed for secure, everyday use.</p>
          <Link to={ROUTES.INDIVIDUALS} target="_blank">zunuprivacy.com</Link>
        </div>

      </div>
    </div>
  )
}

export default Home